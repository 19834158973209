<template>
  <div>
    <b-table
      head-variant="light"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      :items="itens"
      :fields="campos"
      responsive
      show-empty
      stacked="md"
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="visualizar(item.item)">
            {{ $t('GERAL.VISUALIZAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="editar(item.item)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="excluir(item.item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
// AUX & HELPES
import rotas from '@/common/utils/rotas';

// SERVICES

//COMPONENTS

export default {
  name: 'TabelaDocumentosPadrao',
  components: {},
  props: {
    itens: { type: Array, default: Array },
  },
  data() {
    return {
      campos: [
        {
          key: 'codigo',
          label: this.$t('ACADEMICO.CODIGO'),
          sortable: true,
        },
        {
          key: 'nome',
          label: this.$t('GERAL.NOME'),
          formatter: (value) => {
            return value ? value : '-';
          },
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    visualizar(item) {
      this.$emit('visualizar', item);
    },
    editar(item) {
      rotas.irPara(
        'academico-estrutura-cej-formulario-documento-padrao',
        item.id
      );
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
  },
};
</script>

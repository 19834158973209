<template>
  <topbar
    :titulo="$t('ACADEMICO.DOCUMENTO_PADRAO')"
    :subtitulo="$t('ACADEMICO.DOCUMENTOS_PADRAO_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-2 ml-md-4"
      >
        <b-button variant="primary" @click="abrirGuiaTermos">
          {{ $t('ACADEMICO.GUIA_TERMOS') }}
        </b-button>
        <b-button variant="info" @click="cadastrar">
          {{ $t('GERAL.CADASTRAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
        { titulo: this.$t('ACADEMICO.DOCUMENTO_PADRAO') },
      ],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    cadastrar() {
      this.$emit('cadastrar');
    },
    abrirGuiaTermos() {
      this.$emit('abrirModal');
    },
  },
};
</script>

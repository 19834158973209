<template>
  <modal
    id="modalTermos"
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="$t('ACADEMICO.GUIA_TERMOS')"
    :campos="$refs"
  >
    <tabela-guia-termos />
    <template #modal-footer>
      <b-button variant="secondary" @click="fecharModal">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
    </template>
  </modal>
</template>

<script>
// aux e helpers

// components
import Modal from '@/components/modal/Modal.vue';
import TabelaGuiaTermos from '../tabelas/TabelaGuiaTermos.vue';
export default {
  name: 'ModalGuiaDeTermos',
  components: {
    Modal,
    TabelaGuiaTermos,
  },
  props: {
    exibir: { type: Boolean, default: false },
  },

  methods: {
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>

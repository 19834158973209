<template>
  <div>
    <titulo-documento-padrao
      @cadastrar="irParaNovoDocumentoPadrao"
      @abrirModal="abrirModalTermos"
    />

    <tabela-documentos-padrao
      :itens="documentosPadrao"
      @excluir="confirmarExclusao"
      @visualizar="abrirModalDocumentoPadrao"
    />

    <paginacao
      v-model="paginacao"
      :total="total"
      @input="pegarDocumentosPadraoPaginado"
    />

    <modal-guia-termos :exibir="exibirGuiaTermos" @fechar="fecharModalTermos" />
    
    <modal-visualizar-documento
      :item="documentoPadrao"
      :exibir="exibirDocumento"
      @fechar="fecharModalDocumentoPadrao"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services & Models:
import DocumentoPadraoService from '@/common/services/documento-padrao/documento-padrao.service.js';

// Components:
import Paginacao from '@/components/paginacao/Paginacao';
import ModalGuiaTermos from './components/modais/ModalGuiaTermos';
import TabelaDocumentosPadrao from './components/tabelas/TabelaDocumentosPadrao';
import ModalVisualizarDocumento from './components/modais/ModalVisualizarDocumento.vue';
import TituloDocumentoPadrao from '@/views/academico/cej/estrutura/documentos-padrao/components/TituloDocumentoPadrao.vue';

export default {
  name: 'DocumentosPadrao',
  components: {
    TituloDocumentoPadrao,
    Paginacao,
    ModalGuiaTermos,
    TabelaDocumentosPadrao,
    ModalVisualizarDocumento,
  },
  mounted() {
    this.pegarDocumentosPadraoPaginado();
  },
  data() {
    return {
      documentosPadrao: [],
      documentoPadrao: {},
      exibirGuiaTermos: false,
      exibirDocumento: false,
      filtro: {},
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
      },
      total: 0,
    };
  },
  methods: {
    // FUNÇÕES FILTRO:
    pegarDocumentosPadraoPaginado() {
      this.$store.dispatch(START_LOADING);
      DocumentoPadraoService.obterTodosPaginado(this.paginacao)
        .then(({ data }) => {
          this.documentosPadrao = data.itens;
          this.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('ACADEMICO.CONFIRMAR_EXCLUSAO_DE').formatUnicorn({
          item: item.nome,
        }),
        this.$t('ACADEMICO.AVISO_NAO_PODE_DESFAZER_ACAO')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item.id);
      }
    },
    excluir(itemId) {
      this.$store.dispatch(START_LOADING);
      DocumentoPadraoService.excluir(itemId)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.pegarDocumentosPadraoPaginado();
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirModalDocumentoPadrao(documento) {
      this.documentoPadrao = documento;
      this.exibirDocumento = true;
    },
    fecharModalDocumentoPadrao() {
      this.documentoPadrao = {};
      this.exibirDocumento = false;
    },
    // FUNÇÕES MODAL:
    abrirModalTermos() {
      this.exibirGuiaTermos = true;
    },
    fecharModalTermos() {
      this.exibirGuiaTermos = false;
    },
    // FUNÇÕES AUXILIARES:
    irParaNovoDocumentoPadrao(id = null) {
      rotas.irPara('academico-estrutura-cej-formulario-documento-padrao', id);
    },
  },
};
</script>

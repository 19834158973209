<template>
  <modal
    id="modalDocumentoPadrao"
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="$t('ACADEMICO.DOCUMENTO_PADRAO')"
    :campos="$refs"
    tamanho="lg"
  >
    <div>
      <span>
        <strong>{{ $t('GERAL.CODIGO') }}: </strong>
        {{ item.codigo }}
      </span>
      <br />
      <span>
        <strong>{{ $t('GERAL.NOME') }}: </strong>
        {{ item.nome }}
      </span>
    </div>
    <hr />
    <b-card class="bg-gray-100">
      <div v-html="item.documentoHtml" />
    </b-card>
    <template #modal-footer>
      <b-button variant="secondary" @click="fecharModal">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
    </template>
  </modal>
</template>

<script>
// aux e helpers

// components
import Modal from '@/components/modal/Modal.vue';
export default {
  name: 'ModalGuiaDeTermos',
  components: {
    Modal,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: Object },
  },

  methods: {
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
<style scoped>
.bg-gray-100 {
  background: #f9fbfc;
}
</style>
